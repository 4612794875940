
import converter from '@/mixins/converter'
import { apiService } from '../../../services/api.service'

export default converter.extend({
  mixins: [converter],
  props: {
    user: { type: Object, required: true },
    dialogAssign: { type: Boolean, required: true },
    licence: { type: Object, required: true }
  },
  data: () => ({
    licences: [],
    customDate: false,
    menu: false,
    rules: [(v) => !!v || 'Field is required'],
    inValid: false,
    loading: false,
    getEndDate: null,
    inValidInput: null,
    form: true,
    showCustomDate: false,
    arrayRules: [(v) => v.length > 0 || 'Field is required'],
    disabledDates: {
      to: new Date(Date.now() - 8640000)
    },
    licenceBody: {
      id: null,
      licence_duration: null,
      limit: null
    }
  }),

  watch: {
    getEndDate: {
      handler (n) {
        const convertedDate = new Date(n)
        const durationDay = this.calculateExpireTime(convertedDate.getTime())
        this.inValid = false
        this.licenceBody.licence_duration = durationDay
      },
      deep: true
    }
  },

  methods: {
    outsideClose () {
      this.$emit('close-dialog-assign')
      this.clear()
    },
    openCustomDate () {
      this.showCustomDate = true
    },
    async assignToUser () {
      var duration = {
        licence_id: this.licence.licence_keys.id,
        licence_limit: parseInt(this.licenceBody.limit),
        licence_duration: parseInt(this.licenceBody.licence_duration)
      }
      if (this.$refs.newRef.validate()) {
        this.loading = true
        await apiService
          .patch(`licences/assign/?id=${this.user.id}`, duration)
          .then(() => {
            this.$emit('close-dialog-assign')

            this.clear()
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => (this.loading = false))
      }
    },
    clear () {
      this.$refs.newRef.reset()
      this.$refs.newRef.resetValidation()
    },

    disablePastDates (val: any) {
      return val >= new Date().toISOString().substr(0, 10)
    }
  }
})
