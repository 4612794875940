
import { apiService } from '../../../services/api.service'
export default {
  props: {
    licencesInformation: { type: Object },
    featureModel: { type: Boolean },
    features: { type: Array }
  },
  data: () => ({
    numberRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0) return true
      return 'Field has to be greater than 0 '
    },
    headers: [
      { text: 'Label', value: 'label', align: 'center' },
      { text: 'Limit', value: 'limit', align: 'center' }
    ],
    details: [],
    updateLicence: {
      label: '',
      features: [],
      active: false,
      deleted: false,
      licenceId: 0
    },
    loading: false
  }),

  methods: {
    async editLicence () {
      this.updateLicence.label = this.licencesInformation.licence_keys.label
      this.updateLicence.limit = this.licencesInformation.licence_keys.limit
      this.updateLicence.active = this.licencesInformation.licence_keys.active
      this.updateLicence.licenceId = this.licencesInformation.licence_keys.id
      this.updateLicence.duration =
        this.licencesInformation.licence_keys.duration
      for (let index = 0; index < this.features.length; index++) {
        this.updateLicence.features[index] = {
          id: this.features[index].id,
          value: this.features[index].limit
        }
      }
      await apiService
        .put(
          `licences/edit/?id=${this.licencesInformation.licence_keys.id}`,
          this.updateLicence
        )
        .then(() => {
          this.allLicences()
          this.$emit('call-close-dialog')
          this.$emit('call-licences')
        })

      return true
    },
    closeFeatureDialog () {
      this.$emit('close-feature-dialog')
    },

    async allLicences () {
      await apiService
        .get('licences/all')
        .then((res) => {
          this.details = res.data
        })
        .finally(() => (this.licenceLoading = false))
    }
  }
}
