<template>
  <div>
    <v-dialog persistent v-model="featureDialog" max-width="500">
      <template>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="features"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar dense flat>
              <v-toolbar-title>Features</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="showFeatureModal"
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </template>
                Add New Feature
              </v-tooltip>

              <v-btn @click="getFeatures" icon>
                <v-icon dark>mdi-sync</v-icon>
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn @click="close" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <!-- eslint-disable-next-line -->
            <v-btn
              v-for="(action, idx) in actionButtons"
              :key="idx"
              icon
              @click="action.func(item)"
            >
              <v-icon :color="action.color || 'black'">{{
                action.icon
              }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </template>
    </v-dialog>

    <v-dialog v-model="newFeatureModal" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Feature</span>
          <v-chip class="ma-2" small color="primary">New </v-chip>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="py-1"> Feature Name </v-card-title>
        <v-card-text>
          <v-form
            lazy-validation
            v-model="form"
            ref="newFeature"
            :disabled="loading"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="py-1"
                  v-model="featureName"
                  hide-details="auto"
                  clearable
                  :rules="rules"
                  required
                  outlined
                  label="Name of licence"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-text class="d-flex justify-end">
          <v-btn
            class="ml-5"
            :disabled="!form || loading"
            color="primary"
            :loading="loading"
            @click="saveFeature"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Vue from 'vue'
import { apiService } from '../../../services/api.service'
export default Vue.extend({
  props: {
    featureDialog: { type: Boolean, required: true }
  },
  data () {
    return {
      dialog: false,
      features: [],
      featureName: '',
      form: false,
      newFeatureModal: false,
      rules: [(v) => !!v || 'Field is required'],
      loading: false,
      actionButtons: [
        {
          icon: 'mdi-delete-outline',
          condition: {
            deleted: false
          },
          color: 'red',
          func: this.deleteFeature
        }
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'label'
        },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },

  mounted () {
    this.getFeatures()
  },

  methods: {
    close () {
      this.$emit('close-feature-dialog')
    },

    async getFeatures () {
      await apiService.get('licences/features').then((res) => {
        this.features = res.data
      })
    },

    async saveFeature () {
      await apiService
        .post('licences/new-feature', { name: this.featureName })
        .then((res) => {
          this.cancel()
        })
    },

    showFeatureModal () {
      this.newFeatureModal = true
    },

    cancel () {
      this.newFeatureModal = false
      this.getFeatures()
    },

    async deleteFeature (item) {
      const endpoint = `licences/feature?id=${item.id}`
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService.delete(endpoint).catch((err) => {
            Swal.showValidationMessage(`Request failed: ${err}`)
          })
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
          this.cancel()
        }
      })
    }
  }
})
</script>

<style>
</style>
