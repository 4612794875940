<template>
  <div>
    <v-dialog persistent v-model="showEditLicenceDialog" width="500">
      <v-card :loading="loading">
        <v-form
          lazy-validation
          v-model="form"
          ref="customLicenceForm"
          :disable="loading"
        >
          <v-card-title>
            <span class="text-h6"
              >Edit Licence | {{ licenceInfoForEdit.licence_name }}</span
            >
            <v-spacer></v-spacer>
            <v-btn @click="closeEditLicenceDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="font-weight-medium mb-2">Devices</div>
            <v-text-field
              v-model="licenceInfoForEdit.licence_name"
              :rules="[rules.required]"
              color="deep-purple"
              label="*Licence Name"
              outlined
              dense
              class="inputPrice"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="licenceInfoForEdit.price_monthly"
                  :rules="[rules.required, rules.mustMoreThanZero]"
                  color="deep-purple"
                  label="*Price Monthly"
                  type="number"
                  outlined
                  dense
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="licenceInfoForEdit.price_yearly"
                  :rules="[rules.required]"
                  auto-select-first
                  label="*Price Yearly"
                  color="deep-purple"
                  type="number"
                  outlined
                  dense
                  class="inputPrice"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="3">
                <v-switch
                  color="green"
                  v-model="licenceInfoForEdit.is_active"
                  :label="licenceInfoForEdit.is_active ? 'Active' : 'Passive'"
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  color="red"
                  v-model="licenceInfoForEdit.deleted"
                  :label="licenceInfoForEdit.deleted ? 'Deleted' : 'Undeleted'"
                ></v-switch>
              </v-col>
            </v-row>

            <div class="font-weight-medium mb-2">Features</div>
            <div v-for="(feature, idx) in features" :key="idx">
              <v-text-field
                v-model="licenceInfoForEdit.features[idx]['limit']"
                :rules="[rules.required, rules.mustMoreThanZero]"
                color="deep-purple"
                :label="'*' + licenceInfoForEdit.features[idx]['feature_name']"
                type="number"
                outlined
                dense
                class="inputPrice"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="editLicence()"
                :disabled="!form || loading"
                color="primary"
              >
                SAVE
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default {
  props: {
    licenceInfoForEdit: { type: Object, default: () => ({}) },
    showEditLicenceDialog: { type: Boolean, default: false }
  },

  created () {
    this.initialize()
  },

  data: () => ({
    featuresPayload: [],
    features: [],
    editLicencePayload: {},
    loading: false,
    form: true,
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      },
      mustMoreThanZero: (v) => {
        if (!isNaN(v) && v >= 0) return true
        return 'Number must be more or equal 0'
      }
    }
  }),

  methods: {
    async initialize () {
      await apiService
        .get('admin-get-features')
        .then((res) => {
          this.features = res.data.data
          for (let index = 0; index < this.features.length; index++) {
            this.featuresPayload[index] = {
              feature_id: this.features[index].feature_id
            }
          }
        })
        .catch((err) => {
          console.log('ERR', err.response)
        })
    },

    async editLicence () {
      if (this.$refs.customLicenceForm.validate()) {
        await apiService
          .post('admin-edit-licence', this.licenceInfoForEdit)
          .then(() => {
            this.closeEditLicenceDialog()
            Swal.fire(
              'Edited!',
              'Licence is edited to user successfully!',
              'success'
            )
          })
          .catch((err) => {
            console.log('ERR', err.response)
            Swal.fire(
              'Error!',
              'An error occurred while editing the license!',
              'error'
            )
          })
      }
    },

    closeEditLicenceDialog () {
      this.$emit('closeEditLicenceDialog')
    }
  }
}
</script>

    <style>
.inputPrice input[type='number'] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
