
import { apiService } from '../../../services/api.service'
import Swal from 'sweetalert2'
import converter from '../../mixins/converter'
import AddFeatureView from './AddFeatureView.vue'

export default converter.extend({
  components: { AddFeatureView },
  props: {
    dialog: { type: Boolean, required: true },
    licenceInfo: { type: Object, required: true },
    licenceFeatures: { type: Array },
    isEditDialog: { type: Boolean },
    isUser: { type: Boolean },
    user: { type: Object }
  },
  data: () => ({
    rules: [(v) => !!v || 'Field is required'],
    numberRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0) return true
      return 'Field has to be greater than 0 '
    },
    loading: false,
    form: true,
    menu: false,
    panel: [0, 1],
    features: [],
    featuresDialog: false,
    showCustomDate: false,
    addFeatureModel: false,
    getEndDate: null,
    modalFeature: [],
    getFeaturesArray: [],
    selected: false,
    inValid: false,
    newUserLicence: {
      userId: null,
      label: '',
      limit: null,
      features: [],
      active: true,
      duration: null,
      deleted: false,
      licenceId: -1
    },
    newLicence: {
      label: '',
      features: [],
      active: true,
      deleted: false,
      licenceId: -1
    },
    headers: [
      { text: 'Label', value: 'label', align: 'center' },
      { text: 'Limit', value: 'limit', align: 'center' },
      { text: 'Action', value: 'actions', align: 'center' }
    ]
  }),

  mounted () {
    if (!this.isEditDialog) {
      this.getFeatures()
    } else {
      this.setLicencesMounted(this.isUser)
    }
  },

  watch: {
    getFeaturesArray: {
      handler (n) {
        this.watchGetFeatures(n, this.isUser)
      },
      deep: true
    },
    licenceInfo: {
      handler (n) {
        this.watchLicenceInfo(n, this.isUser)
      },
      deep: true
    },
    getEndDate: {
      handler (n) {
        this.watchGetEndDate(n)
      },
      deep: true
    }
  },

  methods: {
    async save (status: boolean) {
      var value = {}
      var endpoint = ''
      if (this.$refs.newRef.validate()) {
        this.loading = true
        if (status) {
          this.newUserLicence.userId = this.user.id
          value = this.newUserLicence
          endpoint = 'licences/generate-for-user'
        } else if (!status) {
          value = this.newLicence
          endpoint = 'licences/create'
        }
        await apiService
          .post(endpoint, value)
          .then((res) => {
            if (status) {
              this.$emit('call-user-licence')
            } else {
              this.callAllLicence()
            }
            this.clear()
            this.$emit('close-dialog')
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!',
              text: ' Please check inputs'
            })
          })
          .finally(() => this.$emit('close-dialog'))
      }
    },

    async update (status: boolean) {
      var value = {}
      var endpoint = ''
      if (this.$refs.newRef.validate()) {
        this.loading = true
        if (status) {
          this.newUserLicence.userId = this.user.id
          endpoint = 'licences/edit-of-user'
          value = this.newUserLicence
        } else if (!status) {
          endpoint = `licences/edit/?id=${this.licenceInfo.licence_keys.id}`
          value = this.newLicence
        }

        await apiService
          .put(endpoint, value)
          .then(() => {
            this.callAllLicence()
            this.clear()
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!',
              text: ' Please check inputs'
            })
          })
          .finally(() => this.$emit('close-dialog'))
      }
    },

    async deleteFeature (item: any) {
      const endpoint = `licences/licence-feature?id=${item.id}`
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService.delete(endpoint).catch((err) => {
            Swal.showValidationMessage(`Request failed: ${err}`)
          })
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
          this.getFeatures()
        }
      })
    },

    watchLicenceInfo (features: [], status: boolean) {
      if (Object.keys(features).length > 0) {
        if (this.isUser) {
          for (let index = 0; index < this.licenceFeatures.length; index++) {
            this.newUserLicence.features[index] = {
              id: this.licenceFeatures[index].licence_features.id,
              value: this.licenceFeatures[index].limit
            }
          }
        } else {
          const licenceFeaturesKey =
            this.licenceInfo.licence_keys.licence_key_feature_assoc
          if (licenceFeaturesKey.length > 0) {
            for (let index = 0; index < licenceFeaturesKey.length; index++) {
              this.newLicence.features[index] = {
                id: licenceFeaturesKey[index].licence_features.id,
                value: parseInt(licenceFeaturesKey[index].limit)
              }
            }
          }
        }
      }
    },

    watchGetEndDate (date: any) {
      const convertedDate = new Date(date)
      const durationDay = this.calculateExpireTime(convertedDate.getTime())
      this.inValid = false
      this.licenceInfo.duration = durationDay
      this.newUserLicence.duration = durationDay
    },

    watchGetFeatures (features: [], status: boolean) {
      if (Object.keys(features).length > 0) {
        if (this.isUser) {
          for (let index = 0; index < features.length; index++) {
            this.newUserLicence.features[index] = {
              id: this.getFeaturesArray[index].id,
              value: this.getFeaturesArray[index].limit
            }
          }
        }
        if (!this.isUser) {
          for (let index = 0; index < features.length; index++) {
            this.newLicence.features[index] = {
              id: this.getFeaturesArray[index].id,
              value: this.getFeaturesArray[index].limit
            }
          }
        }
      }
      this.modalFeature = this.getFeaturesArray
    },

    setLicencesMounted (status: boolean) {
      if (status) {
        const n = this.licenceInfo
        this.modalFeature = this.licenceFeatures
        this.newUserLicence.label = n.licence_keys.label
        this.newUserLicence.limit = n.limit
        this.newUserLicence.duration = n.duration
        this.newUserLicence.licenceId = n.licence_keys.id
        for (let index = 0; index < this.licenceFeatures.length; index++) {
          this.newUserLicence.features[index] = {
            id: this.licenceFeatures[index].licence_features.id,
            value: this.licenceFeatures[index].limit
          }
        }
      } else {
        const n = this.licenceInfo
        this.newLicence.label = n.licence_keys.label
        this.newLicence.licenceId = n.licence_keys.id
        const licenceFeaturesKey =
          this.licenceInfo.licence_keys.licence_key_feature_assoc
        this.modalFeature = licenceFeaturesKey
        if (licenceFeaturesKey.length > 0) {
          for (let index = 0; index < licenceFeaturesKey.length; index++) {
            this.newLicence.features[index] = {
              id: licenceFeaturesKey[index].licence_features.id,
              value: licenceFeaturesKey[index].limit
            }
          }
        }
      }
    },

    async getFeatures () {
      await apiService.get('licences/features').then((res) => {
        this.features = res.data
        if (!this.isEditDialog) {
          this.getFeaturesArray = res.data
        }
      })
    },

    openCustomDate () {
      this.showCustomDate = true
    },

    disablePastDates (val: any) {
      return val >= new Date().toISOString().substr(0, 10)
    },

    openAddFeatureModel () {
      this.getFeatures()
      this.addFeatureModel = true
    },

    callAllLicence () {
      this.$emit('call-all-licence')
    },

    clear () {
      this.$refs.newRef.reset()
      this.$refs.newRef.resetValidation()
    },

    closeDialog () {
      this.$emit('close-dialog')
      if (!this.isEditDialog) {
        this.newUserLicence = {}
      }
    }
  }
})
