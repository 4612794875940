<template>
  <div>
    <v-dialog persistent v-model="showCustomLicenceDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="openCustomerLicenceDialog()"
          color="primary"
          class="ml-2"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left> mdi-plus </v-icon>
          New Licence
        </v-btn>
      </template>

      <v-card :loading="loading">
        <v-form
          lazy-validation
          v-model="form"
          ref="customLicenceForm"
          :disable="loading"
        >
          <v-card-title>
            <span class="text-h6">New Licence</span>
            <v-spacer></v-spacer>
            <v-btn @click="closeCustomLicenceDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="font-weight-medium mb-2">Devices</div>
            <v-text-field
              v-model="newLicencePayload.licence_name"
              :rules="[rules.required]"
              color="deep-purple"
              label="*Licence Name"
              outlined
              class="inputPrice"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newLicencePayload.price_monthly"
                  :rules="[rules.required]"
                  color="deep-purple"
                  label="*Price Monthly"
                  type="number"
                  outlined
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newLicencePayload.price_yearly"
                  :rules="[rules.required]"
                  auto-select-first
                  label="*Price Yearly"
                  color="deep-purple"
                  type="number"
                  outlined
                  class="inputPrice"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="font-weight-medium mb-2">Features</div>
            <div v-for="(feature, idx) in features" :key="idx">
              <v-text-field
                v-model="featuresPayload[idx]['limit']"
                :rules="[rules.required]"
                color="deep-purple"
                :label="'*' + feature.label"
                type="number"
                outlined
                class="inputPrice"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="createNewLicence()"
                :disabled="!form || loading"
                color="primary"
              >
                SAVE
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default {
  props: {
    newLicence: { type: Boolean, default: false }
    // showCustomLicenceDialog: { type: Boolean, default: false }
  },

  created () {
    this.initialize()
  },

  data: () => ({
    editDialog: false,
    loading: false,
    form: true,
    showCustomLicenceDialog: false,
    newLicencePayload: {},
    featuresPayload: [],
    features: [],
    addressPayload: {},
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),

  methods: {
    async initialize () {
      await apiService
        .get('admin-get-features')
        .then((res) => {
          this.features = res.data.data
          for (let index = 0; index < this.features.length; index++) {
            this.featuresPayload[index] = {
              feature_id: this.features[index].feature_id
            }
          }
        })
        .catch((err) => {
          console.log('ERR', err.response)
        })
    },

    async createNewLicence () {
      const customLicencePayload = {
        licence_name: this.newLicencePayload.licence_name,
        price_monthly: this.newLicencePayload.price_monthly,
        price_yearly: this.newLicencePayload.price_yearly,
        features: this.featuresPayload
      }
      if (this.$refs.customLicenceForm.validate()) {
        await apiService
          .post('admin-custom-licence', customLicencePayload)
          .then(() => {
            this.closeCustomLicenceDialog()
            this.$emit('getAllLicences')
            Swal.fire(
              'Assigned!',
              'Licence is assigned to user successfully!',
              'success'
            )
          })
          .catch((err) => {
            console.log('ERR', err.response)
            Swal.fire(
              'Error!',
              'An error occurred while assigning the license!',
              'success'
            )
          })
      }
    },

    closeCustomLicenceDialog () {
      this.showCustomLicenceDialog = false
      this.$refs.customLicenceForm.reset()
    },

    openCustomerLicenceDialog () {
      this.showCustomLicenceDialog = true
    }
  }
}
</script>

  <style>
.inputPrice input[type='number'] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
