<template>
  <div>
    <v-dialog persistent v-model="showLicenceFeaturesDialog" width="500">
      <v-card>
        <v-toolbar dense color="indigo" dark>
            <v-toolbar-title>{{ licenceName }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn @click="closeLicenceKeyFeature" icon>
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
          </v-toolbar>
        <v-data-table dense :headers="headersFeature" :items="features">
          <template v-slot:[`item.label`]="{ item }">
            <span>{{ item.feature_name }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    features: { type: Array, default: () => [] },
    showLicenceFeaturesDialog: { type: Boolean, default: false },
    licenceName: { type: String, default: '' }
  },
  data () {
    return {
      headersFeature: [
        {
          text: 'Name',
          value: 'label',
          align: 'left'
        },
        { text: 'Limit', value: 'limit', align: 'center' }
      ]
    }
  },
  methods: {
    closeLicenceKeyFeature () {
      this.$emit('closeLicenceKeyFeature')
    }
  }
}
</script>

<style>
</style>
