<template>
  <div>
    <v-card :loading="licenceLoading">
      <v-card-title color="rgba(0, 0, 0, 0)" class="font-weight-regular" flat>
      Licences
      <v-divider class="ml-2" vertical></v-divider>
      <v-btn @click="initialize" icon>
        <v-icon dark>mdi-sync</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          class="mr-2"
          clearable
          dense
          hide-details
          label="Search licence.."
          outlined
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>

        <v-spacer></v-spacer>

        <!-- <v-btn @click="initialize" icon>
          <v-icon dark>mdi-sync</v-icon>
        </v-btn> -->
        <!-- <v-btn
          color="primary"
          class="ml-2"
          outlined
          @click="openFeaturesDialog"
          disabled
        >
          <v-icon left> mdi-decagram-outline </v-icon>
          Features
        </v-btn> -->

        <new-licence @getAllLicences="allLicences"></new-licence>

        <edit-licence-form
          @closeEditLicenceDialog="closeEditLicenceDialog"
          :showEditLicenceDialog="showEditLicenceDialog"
          :licenceInfoForEdit="editLicenceProp"
        ></edit-licence-form>

      </v-card-title>
      <v-data-table :headers="headers" :items="details" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-for="(action, idx) in actionButtons"
            :key="idx"
            icon
            @click="action.func(item)"
          >
            <v-icon :color="action.color || 'black'">{{ action.icon }}</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ timeParserDate(item.created_at) }}</span>
        </template>

        <template v-slot:[`item.started`]="{ item }">
          <span>{{ timeParserDate(item.started) }}</span>
        </template>

        <template v-slot:[`item.features`]="{ item }">
          <v-btn @click="getFeatureLimit(item)" small color="grey" dark
            >See Features
          </v-btn>
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <v-chip
            v-if="item.is_active"
            label
            small
            color="green lighten-1 ml-2 mb-1"
            ><span class="white--text">Active</span></v-chip
          >
          <v-chip v-else label small color="red lighten-1 ml-2 mb-1"
            ><span class="white--text">Passive</span></v-chip
          >
        </template>

        <template v-slot:[`item.deleted`]="{ item }">
          <span><v-icon :color="item.deleted ? 'red': 'grey'">mdi-{{ item.deleted ? 'check' : 'minus' }}</v-icon></span>
        </template>

        <template v-slot:[`item.licence_name`]="{ item }">
          <v-chip class="set-chip-width" small label color="indigo" dark>
            <span>{{ item.licence_name }}</span>
          </v-chip>
        </template>

        <template v-slot:[`item.price_monthly`]="{ item }">
          <span style="color: #42a5f5">{{ item.price_monthly }} $</span>
        </template>

        <template v-slot:[`item.price_yearly`]="{ item }">
          <span style="color: #42a5f5">{{ item.price_yearly }} $</span>
        </template>
      </v-data-table>
    </v-card>

    <licence-key-feature
      :showLicenceFeaturesDialog="showLicenceFeatures"
      :features="features"
      :licenceName="licenceName"
      @closeLicenceKeyFeature="showLicenceFeatures = false"
    ></licence-key-feature>
  </div>
</template>

<script>
import converter from '@/mixins/converter'
import LicenceGenerate from './LicenceGenerate.vue'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import LicenceFeatures from './LicenceFeatures.vue'
import LicenceAssign from './LicenceAssign.vue'
import NewLicence from './NewLicence.vue'
import LicenceKeyFeature from '../features/LicenceKeyFeature.vue'
import EditLicenceForm from './EditLicenceForm.vue'
export default converter.extend({
  components: {
    LicenceGenerate,
    LicenceFeatures,
    LicenceAssign,
    NewLicence,
    LicenceKeyFeature,
    EditLicenceForm
  },
  props: {
    assignAction: { type: Object },
    isAssign: { type: Boolean },
    user: { type: Object }
  },
  data () {
    return {
      details: [],
      features: [],
      editLicenceProp: {},

      licenceName: '',
      licenceLoading: false,
      search: null,
      showLicenceModal: false,
      licence_generate_model: false,
      showEditLicenceDialog: false,
      showLicenceFeatures: false,
      showCreateCustomLicenceDialog: false,
      isEditDialog: false,
      dialogFeatures: false,
      showDialogAssign: false,
      actionButtons: [
        {
          icon: 'mdi-pencil-circle-outline',
          color: 'grey',
          func: this.edit_licence
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.delete_licence,
          condition: {
            deleted: false
          }
        }
        // {
        //   icon: 'mdi-plus-circle-outline',
        //   func: this.assignLicence,
        //   color: 'green'
        // }
      ],

      headers: [
        {
          text: 'Name',
          value: 'licence_name',
          color: 'red',
          align: 'center'
        },
        { text: 'Active', value: 'is_active', align: 'center' },
        { text: 'Deleted', value: 'deleted', align: 'center' },
        {
          text: 'Created Time',
          value: 'created_at',
          align: 'center'
        },
        { text: 'Monthly Price', value: 'price_monthly', align: 'center' },
        { text: 'Yearly Price', value: 'price_yearly', align: 'center' },

        { text: 'Features', value: 'features', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ]
    }
  },
  mounted () {
    this.initialize()
  },

  methods: {
    async initialize () {
      this.licenceLoading = true
      this.allLicences()
    },

    assignLicence (item) {
      this.editLicenceProp = item
      this.showDialogAssign = true
      this.showLicenceModal = true
    },

    async allLicences () {
      this.licence_generate_model = false
      await apiService
        .get('admin-get-licences')
        .then((res) => {
          this.details = res.data.data
        })
        .finally(() => (this.licenceLoading = false))
        .catch((err) => {
          console.log('ERR', err.response)
        })
    },

    openFeaturesDialog () {
      this.dialogFeatures = true
    },

    edit_licence (item) {
      this.showEditLicenceDialog = true
      this.editLicenceProp = item
    },

    getFeatureLimit (item) {
      this.licenceName = item.licence_name
      this.features = item.features
      this.showLicenceFeatures = true
    },

    closeEditLicenceDialog () {
      this.showEditLicenceDialog = false
      this.allLicences()
    },

    async delete_licence (licence) {
      // const endpoint = `admin-delete-licence/${licence.id}`
      Swal.fire({
        title: 'Are you sure?',
        text: "The license will be permanently deleted. You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.value) {
          await apiService
            .get(`admin-delete-permanently-licence/${licence.id}`).catch((err) => {
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
          this.allLicences()
          Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
        }
      })
    }
  }
})
</script>

<style>
.set-chip-width {
  width: 80px;
}

.set-chip-width span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
