import Vue from 'vue'

export default Vue.extend({
  methods: {
    timeParser (timeString: string) {
      const a = new Date(parseInt(timeString) * 1000)
      const zeroPad = (num: number, places: number) =>
        String(num).padStart(places, '0')
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const year = a.getFullYear()
      const month = months[a.getMonth()]
      const date = a.getDate()
      const hour = zeroPad(a.getHours(), 2)
      const min = zeroPad(a.getMinutes(), 2)
      const sec = zeroPad(a.getSeconds(), 2)
      return date + ' ' + month + ' ' + year
    },

    calculateExpireTime (endTime: number) {
      const today = new Date().getTime()
      const diffTime = Math.abs(endTime - today)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },

    shortenChipset (chipsetId: number) {
      switch (chipsetId) {
        case 0:
          return 'SW'
        case 24:
          return 'TX2'
        case 33:
          return 'Nano'
        case 25:
          return 'NX/AGX'
      }
    },
    secondsToDhms (seconds: number) {
      // https://stackoverflow.com/questions/36098913/convert-seconds-to-days-hours-minutes-and-seconds
      seconds = Number(seconds)
      const d = Math.floor(seconds / (3600 * 24))
      const h = Math.floor((seconds % (3600 * 24)) / 3600)
      const m = Math.floor((seconds % 3600) / 60)
      const s = Math.floor(seconds % 60)

      const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : ''
      const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
      const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
      const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
      return dDisplay + hDisplay + mDisplay + sDisplay
    },

    modelChipsets (model: any, v = 'chipset_ids') {
      let chipsets = new Array<any>()
      for (const child in model.children) {
        if (Object.hasOwnProperty.call(model.children, child)) {
          const c = model.children[child]
          if (c[v]) chipsets = (new Array<any>()).concat(chipsets, c[v])
        }
      }
      if (chipsets.length === 0 && model.children.length === 0) {
        // model itself
        return model[v]
      }
      return [...new Set(chipsets)] // return uniques values only
    },

    timeParserDate (timeString: string, format = null) {
      const a = new Date(timeString)
      const zeroPad = (num: number, places: number) =>
        String(num).padStart(places, '0')
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const year = a.getFullYear()
      const month = months[a.getMonth()]
      const date = a.getDate()
      return date + ' ' + month + ' ' + year
    },
    formatBytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1000
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  }
})
